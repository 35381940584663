import { useMemo } from "react";

export interface CommercetoolsMoneyData {
    type: string;
    currencyCode: string; // ISO 4217
    centAmount: number;
    fractionDigits: number;
}

export interface SimpleMoneyData extends Omit<CommercetoolsMoneyData, "type"> {}

export interface AlgoliaMoneyData {
    currencyCode: string;
    amount: number;
    fractionDigits: number;
}

export interface UseMoneyValue {
    currencyCode: string;
    currencyName?: string;
    currencySymbol?: string;
    currencyNarrowSymbol?: string;
    amount: string;
    parts: Intl.NumberFormatPart[];
    localizedString: string;
    original: CommercetoolsMoneyData | SimpleMoneyData | AlgoliaMoneyData;
    withoutTrailingZeros: string;
    withoutTrailingZerosAndCurrency: string;
}

export interface UseMoneyOptions {
    money: CommercetoolsMoneyData | SimpleMoneyData | AlgoliaMoneyData;
    locale: string;
    convertFromCents?: boolean;
}

export const useMoney = ({
    money,
    locale,
    convertFromCents = true,
}: UseMoneyOptions): UseMoneyValue => {
    const options = useMemo(
        () => ({
            style: "currency",
            currency: money?.currencyCode || "USD",
        }),
        [money?.currencyCode]
    );

    const amount = useMemo(() => {
        /**
         * https://docs.commercetools.com/api/types#moneys
         *
         * amount(money) in commercetools is represented as:
         *  - Cents for EUR and USD, pence for GBP, or centime for CHF (5 CHF is specified as 500).
         *  - The value in the major unit for currencies without minor units, like JPY (5 JPY is specified as 5).
         *
         *  amount(money) in Algolia is calculated as part of pushing data to the indices and is already converted to an appropriate float.
         *
         * The value passed to Intl.NumberFormat.format is expected to be a float or an int (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat),
         * thus - if we work with commercetools data - we will need to convert from the smallest indivisible unit,
         * to an appropriately formatted float (or int). Since fraction digits varies between currencies,
         * we use exponentiation to ensure we convert correctly.
         *
         * I am not a math teacher, so I cannot explain the details of how exponentiation works:
         * https://en.wikipedia.org/wiki/Exponentiation
         *
         * This implementation is based on the following implementations:
         * - formula from commercetools Sunrise app: https://github.com/commercetools/sunrise-spa/blob/master/src/presentation/fashion/components/BaseMoney/BaseMoney.js#L22
         * - UI: https://github.com/Shopify/hydrogen/blob/e615b3fe7901f8f952b66d2bc021e01cdb3e528b/packages/hydrogen/src/components/Money/Money.client.tsx
         * - hook: https://github.com/Shopify/hydrogen/blob/e615b3fe7901f8f952b66d2bc021e01cdb3e528b/packages/hydrogen/src/hooks/useMoney/hooks.tsx#L58
         */
        return convertFromCents
            ? (money as CommercetoolsMoneyData | SimpleMoneyData)?.centAmount /
                  10 ** money?.fractionDigits
            : (money as AlgoliaMoneyData)?.amount;
    }, [money, convertFromCents]);

    const value = useMemo(
        () => new Intl.NumberFormat(locale, options).format(amount),
        [amount, locale, options]
    );

    const baseParts = new Intl.NumberFormat(locale, options).formatToParts(amount);

    const nameParts = new Intl.NumberFormat(locale, {
        ...options,
        // ts error: currencyDisplay is only supported from ES2020 or later.
        // @ts-ignore
        currencyDisplay: "name",
    }).formatToParts(amount);

    const narrowParts = new Intl.NumberFormat(locale, {
        ...options,
        // ts error: currencyDisplay is only supported from ES2020 or later.
        // @ts-ignore
        currencyDisplay: "narrowSymbol",
    }).formatToParts(amount);

    const withoutTrailingZerosFormatter = new Intl.NumberFormat(locale, {
        ...options,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const withoutCurrencyFormatter = new Intl.NumberFormat(locale);

    const withoutTrailingZerosOrCurrencyFormatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const withoutTrailingZeros =
        amount % 1 === 0 ? withoutTrailingZerosFormatter.format(amount) : value;

    const withoutTrailingZerosAndCurrency =
        amount % 1 === 0
            ? withoutTrailingZerosOrCurrencyFormatter.format(amount)
            : withoutCurrencyFormatter.format(amount);

    return useMemo<UseMoneyValue>(
        () => ({
            currencyCode: money?.currencyCode,
            currencyName:
                nameParts.find((part) => part.type === "currency")?.value ?? money?.currencyCode,
            currencySymbol:
                baseParts.find((part) => part.type === "currency")?.value ?? money?.currencyCode,
            currencyNarrowSymbol: narrowParts.find((part) => part.type === "currency")?.value ?? "",
            parts: baseParts,
            localizedString: value,
            amount: baseParts
                .filter((part) =>
                    ["decimal", "fraction", "group", "integer", "literal"].includes(part.type)
                )
                .map((part) => part.value)
                .join(""),
            original: money,
            withoutTrailingZeros,
            withoutTrailingZerosAndCurrency,
        }),
        [
            baseParts,
            money,
            nameParts,
            narrowParts,
            value,
            withoutTrailingZeros,
            withoutTrailingZerosAndCurrency,
        ]
    );
};
