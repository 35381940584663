export const styles: { [x: string]: { [y: string]: any } } = {
    container: {
        height: "auto",
        justifyContent: "space-around",
        alignItems: "center",
        gap: 2,
    },

    animatedContainer: {
        a: { color: "black" },
        alignItems: "center",
        display: "flex",
        p: {
            mb: 0,
            py: "1",
            fontWeight: "bold",
            color: "black",
            textAlign: "center",
            fontSize: ["desktopBodyTextTiny", "desktopBodyTextTiny", "desktopBodyTextSmall"],
        },
    },
};
