import { forwardRef } from "@ui/utils/theme-tools";
import { VisuallyHidden } from "@ui/components/other/visually-hidden/VisuallyHidden";
import { useBreakpointValue } from "@ui/hooks/useBreakpointValue";
import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import type { IModuleUspBanner } from "@contentful-api/types/contentful";
import type { Document } from "@contentful/rich-text-types";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { AnimatePresence, motion } from "framer-motion";
import { omit } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { styles } from "./USPbanner.styles";

export interface IUSPBannerProps {
    uspBanner: IModuleUspBanner;
    bannerBackground?: string;
}

const BANNER_DELAY = 5000;

export const USPbanner = forwardRef(({ uspBanner, bannerBackground }: IUSPBannerProps, ref) => {
    const [index, setIndex] = useState<number>(0);
    const [isRunning, setIsRunning] = useState<boolean>(true);
    const timeoutRef = useRef<NodeJS.Timeout>(null);
    const [isMultiline, setIsMultiline] = useState<boolean>(false);
    const uspBannerFields = uspBanner?.fields;
    const isMobile = useBreakpointValue({ base: true, md: false });
    const uspMessages: Document[] = [
        ...Object.values(omit(uspBannerFields, "internalName")).filter(Boolean),
    ];

    const checkMultiline = (fieldMessage: Document) => {
        const content = fieldMessage.content?.[0]?.content?.[0];

        if (content?.nodeType === "text") {
            setIsMultiline(content.value.length > (isMobile ? 50 : 120));
        }
    };

    useEffect(() => {
        checkMultiline(uspMessages[index]);
    }, [index, isMobile]);

    useEffect(() => {
        const updateIndex = () => {
            setIndex((prevIndex) => (prevIndex === uspMessages.length - 1 ? 0 : prevIndex + 1));
        };

        if (isRunning && uspMessages.length > 1) {
            timeoutRef.current = setInterval(updateIndex, BANNER_DELAY);
        }

        return () => {
            if (timeoutRef.current) {
                clearInterval(timeoutRef.current);
            }
        };
    }, [isRunning, uspMessages.length]);
    /* For decision of the designers the color right now will be fixed, at some point should be a value coming from CF */
    return (
        <Box ref={ref} w="100%" shadow={"base"} px={0}>
            <VisuallyHidden>
                {uspMessages.map((item, i) => (
                    <Box key={`uspMessage${i}`}>{renderRichTextToComponent(item)}</Box>
                ))}
            </VisuallyHidden>
            <Flex
                aria-hidden="true"
                sx={{
                    ...styles.container,
                    height: isMultiline ? "100%" : "6",
                    justifyContent: uspMessages.length > 1 ? "space-between" : "space-around",
                    backgroundColor: bannerBackground ?? "gray.100",
                }}
                data-testid="USPBanner"
            >
                {uspMessages.length > 1 && (
                    <IconButton
                        visibility={["visible"]}
                        transform={"rotate(90deg)"}
                        aria-label="Left Arrow"
                        variant="tertiary"
                        colorScheme="transparent"
                        bg="initial"
                        sx={{
                            _hover: {
                                background: "initial !important",
                            },
                        }}
                        icon={<ECCOIcon name="chevron" color="black" />}
                        size="sm"
                        onClick={() => {
                            setIndex((prevIndex: number) =>
                                prevIndex === 0 ? uspMessages.length - 1 : prevIndex - 1
                            );
                            setIsRunning(false);
                        }}
                    />
                )}
                <AnimatePresence>
                    <Box sx={styles.animatedContainer}>
                        {uspMessages.map(
                            (item, i) =>
                                i === index && (
                                    <motion.div
                                        key={i}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        {renderRichTextToComponent(item)}
                                    </motion.div>
                                )
                        )}
                    </Box>
                </AnimatePresence>
                {uspMessages.length > 1 && (
                    <IconButton
                        visibility={["visible"]}
                        transform={"rotate(-90deg)"}
                        aria-label="Right Arrow"
                        variant="tertiary"
                        colorScheme="transparent"
                        bg="transparent"
                        icon={<ECCOIcon name="chevron" color="black" />}
                        size="sm"
                        sx={{
                            _hover: {
                                background: "initial !important",
                            },
                        }}
                        onClick={() => {
                            setIndex((prevIndex: number) =>
                                prevIndex === uspMessages.length - 1 ? 0 : prevIndex + 1
                            );
                            setIsRunning(false);
                        }}
                    />
                )}
            </Flex>
        </Box>
    );
});

export default USPbanner;
