import type { SystemStyleObject } from "@ui/utils/theme-tools";
import { scrollbarStyles } from "@ui/css/01.scrollbar/scrollbar.styles";
import { defaultUnderlineStyle } from "@ui/css/common";

export const linkStyle: SystemStyleObject = {
    w: "100%",
    display: "flex",
    textDecoration: "none",
    gap: 2,
    alignItems: "center",
    _hover: {
        color: "inherit",
        ...defaultUnderlineStyle,
    },
};

export const dropdownButtonStyle: SystemStyleObject = {
    height: 10.4,
    minW: {
        sm: "3xs",
        base: "2xs",
    },
    span: {
        display: "flex",
        gap: 2,
        alignItems: "center",
    },
};

export const dropdownItemStyle: SystemStyleObject = {
    pt: 0,
    pb: 0,
    ":last-child": {
        pb: 4,
    },
};

export const selectDropdownListStyle: SystemStyleObject = {
    width: {
        base: "calc(100vw - 80px)",
        sm: "countrySelector.width.base",
        md: "countrySelector.width.tablet",
        "2xl": "countrySelector.width.base",
    },
    display: "flex",
    gap: 3,
    flexDirection: "column",
    h: "countrySelector.height",
    overscrollBehavior: "contain",
    ...scrollbarStyles,
    overflowY: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "thin transparent",
    "::after": {
        content: '""',
        width: "50%",
        mb: 4,
        position: "absolute",
        bottom: 0,
        left: 0,
        h: 8,
        background: "linear-gradient(rgba(255, 255, 255, 0.001), #EEEEEE)",
    },
};

export const flagStyle: SystemStyleObject = {
    w: "icon.xs",
    h: "icon.2xs",
};
