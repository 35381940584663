export const styles = {
    fieldStyle: {
        borderBottom: "3.4px solid white !important",
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        width: "115%",
        mb: 4,
        px: 0,
        py: 1,
    },
    fieldErrorStyle: {
        borderBottom: "3.4px solid errorOnBlack !important",
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        width: "115%",
        mb: 4,
        px: 0,
        py: 1,
    },
    policyAgreementLabelP: {
        m: 0,
        textAlign: "left",
        color: "white",
        fontSize: ["mobileBodyTextNormal", "desktopBodyTextNormal"],
        fontWeight: "bold",
    },
    policyAgreementLabelSpan: {
        m: 0,
        color: "black",
        fontSize: ["mobileBodyTextNormal", "desktopBodyTextNormal"],
        fontWeight: "bold",
    },
    policyCheckBox: {
        _hover: {
            borderRadius: 1,
            borderWidth: 1,
            borderColor: "white",
        },
        px: 0,
        py: 0,
        mb: "1",
        borderRadius: 1,
        borderWidth: 1,
    },
    signUpButton: {
        maxHeight: "46px",
        mt: [0, 6],
    },
    emailFormContainer: {
        alignItems: "flex-start",
        justifyContent: "space-between",
        bg: "black",
    },
};
