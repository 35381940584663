export const styles = {
    containerStyle: {
        position: "fixed",
        right: 0,
        bottom: 0,
        zIndex: 7,
        transitionProperty: "common",
    },
    backToTopButton: {
        visibility: "hidden",
        pointerEvents: "none",
        opacity: 0,
        // Negative margin to account for button height.
        height: "10.4",
        mt: "-10.4",
        right: ["layoutMargin.mobile", "layoutMargin.tab", "layoutMargin.desktop"],
        bottom: ["layoutMargin.mobile", "layoutMargin.tab", "layoutMargin.desktop"],
        backdropFilter: "blur(7.5px)",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
        bgColor: "whiteTransparent",
        float: "right",
    },
};
