import type Country from "@interfaces/Country";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { Link } from "@ui/components/navigation/link/Link";
import {
    SelectDropdown,
    SelectDropdownButton,
    SelectDropdownItem,
    SelectDropdownList,
} from "@ui/components/overlay/select-dropdown/SelectDropdown";
import { useBreakpointValue } from "@ui/hooks/useBreakpointValue";
import {
    dropdownButtonStyle,
    dropdownItemStyle,
    flagStyle,
    linkStyle,
    selectDropdownListStyle,
} from "./styles";

interface CountrySelectorProps {
    locationList: Country[];
    currentLocationId: string;
}

export const CountrySelector = ({ locationList, currentLocationId }: CountrySelectorProps) => {
    const { get: getMicrocopy, getMultiple } = useMicrocopy();
    const microcopies = (() => {
        return getMultiple(CommonCMS.global, [
            CommonCMS.openCountrySelector,
            CommonCMS.countrySelector,
        ]);
    })();

    const currentLocation = locationList.find(({ id }) => id === currentLocationId);
    const isMobile = useBreakpointValue({ base: true, sm: false });

    return (
        <Flex justifyContent="flex-end" alignItems="center" zIndex={2}>
            <SelectDropdown
                eventListeners={{
                    scroll: false,
                }}
                placement={isMobile ? "top-start" : "top-end"}
            >
                {({ isOpen }) => (
                    <>
                        <SelectDropdownButton
                            sx={dropdownButtonStyle}
                            isOpen={isOpen}
                            aria-label={microcopies[CommonCMS.openCountrySelector]}
                        >
                            <NextImage
                                sx={flagStyle}
                                src={currentLocation?.icon}
                                alt={currentLocation?.name}
                                sizes={["5vw"]}
                                quality={10}
                            />
                            {currentLocation?.name}
                        </SelectDropdownButton>
                        <SelectDropdownList
                            aria-label={microcopies[CommonCMS.countrySelector]}
                            p={4}
                            bg="gray.50"
                        >
                            <Box sx={selectDropdownListStyle} role="menu">
                                {locationList.map(({ id, name, icon, link }) => (
                                    <SelectDropdownItem
                                        value={id}
                                        key={id}
                                        px={0}
                                        sx={dropdownItemStyle}
                                        aria-label={getMicrocopy(
                                            CommonCMS.global,
                                            CommonCMS.countrySelectorItem,
                                            {
                                                replacements: {
                                                    countryName: name,
                                                },
                                            }
                                        )}
                                    >
                                        <Link sx={linkStyle} href={link}>
                                            <NextImage
                                                blurDataURL="/assets/flags/skeleton.webp"
                                                sx={flagStyle}
                                                src={icon}
                                                alt={name}
                                                sizes={["5vw"]}
                                                quality={10}
                                            />
                                            {name}
                                        </Link>
                                    </SelectDropdownItem>
                                ))}
                            </Box>
                        </SelectDropdownList>
                    </>
                )}
            </SelectDropdown>
        </Flex>
    );
};
