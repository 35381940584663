import type {
    EccoAddDiscountToCartMutationVariables,
    EccoCreateMyCartMutationVariables,
    EccoGetMyActiveCartQueryVariables,
    EccoGetMyActiveDataQueryVariables,
    EccoRemoveDiscountFromCartMutationVariables,
    GetMasterProductsQueryVariables,
    ProductQueryResult,
    RecalculateCartMutationVariables,
} from "@graphql/generated/components";
import {
    EccoGetMyActiveCartDocument,
    EccoGetMyActiveDataDocument,
    GetMasterProductsDocument,
    useEccoAddDiscountToCartMutation,
    useEccoCreateMyCartMutation,
    useEccoRemoveDiscountFromCartMutation,
    useRecalculateCartMutation,
} from "@graphql/generated/components";
import { useRouter } from "next/router";
import { useClient } from "urql";
import { updateMutation, updateQuery, useStore } from ".";

export const useCreateMyCart = () => {
    const [_, execute] = useEccoCreateMyCartMutation();
    const setData = useStore((state) => state.setCart);
    return updateMutation<EccoCreateMyCartMutationVariables>(execute, setData);
};

export const useGetMyActiveCart = () => {
    const client = useClient();
    const router = useRouter();
    const setData = useStore((state) => state.setCart);

    const selector = (data: any) => {
        return data.me.activeCart;
    };

    return updateQuery<EccoGetMyActiveCartQueryVariables>(
        client.query,
        setData,
        selector,
        EccoGetMyActiveCartDocument,
        {
            locale: router.locale,
        }
    );
};

export const useGetMyActiveData = () => {
    const client = useClient();
    const router = useRouter();
    const setData = useStore((state) => state.setActiveData);

    const selector = (data: any) => {
        return {
            cart: data.me.activeCart,
            wishlist: data.me.shoppingLists.results[0],
        };
    };

    return updateQuery<EccoGetMyActiveDataQueryVariables>(
        client.query,
        setData,
        selector,
        EccoGetMyActiveDataDocument,
        {
            locale: router.locale,
        }
    );
};

export const useGetMasterDataForLineItems = () => {
    const client = useClient();
    const setData = useStore((state) => state.setMasterProducts);

    const selector = (data: { products: ProductQueryResult }) => {
        const map = new Map();

        data.products?.results?.forEach((item) => {
            if (!map.has(item.id)) map.set(item.id, item.masterData.current);
        });

        return map;
    };

    const filter = {
        skus: "$skus",
        channelIds: "$channelIds",
    };

    return updateQuery<GetMasterProductsQueryVariables>(
        client.query,
        setData,
        selector,
        GetMasterProductsDocument,
        filter
    );
};

export const useApplyDiscountCodeToCart = () => {
    const setData = useStore((state) => state.setCart);
    const [_, execute] = useEccoAddDiscountToCartMutation();
    return updateMutation<EccoAddDiscountToCartMutationVariables>(execute, setData);
};

export const useRemoveDiscountCodeFromCart = () => {
    const setData = useStore((state) => state.setCart);
    const [_, execute] = useEccoRemoveDiscountFromCartMutation();
    return updateMutation<EccoRemoveDiscountFromCartMutationVariables>(execute, setData);
};

export const useRecalculateCart = () => {
    const setData = useStore((state) => state.setCart);
    const [_, execute] = useRecalculateCartMutation();
    return updateMutation<RecalculateCartMutationVariables>(execute, setData);
};
