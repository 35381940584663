import { space } from "@ui/design-tokens";

const mobileMediaQuery = "@media (max-width: 48em)";

export const styles = {
    mainContainer: {
        px: 0,
        maxW: "100%",
        bg: "white",
        position: "relative",
        bottom: 0,
        zIndex: 20,
    },
    container: {
        px: space["6"],
        py: [2, 2],
        maxW: "initiate",
    },
    menuContainer: {
        py: 2,
        justifyContent: ["flex-start", "space-between"],
        flexDirection: ["column-reverse", "column-reverse", "row"],
        lineHeight: ["normal"],
    },
    divider: {
        borderColor: "gray.100",
    },
    copyright: {
        my: [4, 2],
        mr: [2, 4, 8],
        fontSize: "xs",
        color: "gray.500",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: ["end", "start", "start", "start"],
    },
    policies: {
        my: [4, 4],
        mb: [0, 0, 4],
        fontSize: "sm",
        fontWeight: "semibold",
        textAlign: { base: "start", md: "end" },
        justifyContent: ["start", "start", "start", "end"],
    },
    link: {
        textDecoration: "none",
        mr: [2, 4, 8],
        pb: { base: 4, md: 0 },
        "&:last-of-type": {
            mr: 0,
        },
        [mobileMediaQuery]: {
            display: "flex",
        },
    },
    button: {
        textDecoration: "none",
        h: "auto",
        [mobileMediaQuery]: {
            display: "flex",
        },
    },
};
