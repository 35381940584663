import { Button } from "@ui/components/forms/button/Button";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { useState, useEffect, useCallback } from "react";
import { styles } from "./styles";
import { useThemeMediaQuery } from "@ui/hooks/useThemeMediaQuery";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { CommonCMS } from "@lib/constants/contentful";
import { throttle } from "lodash-es";
import { Box } from "@ui/components/layout/box/Box";

const BackToTop = () => {
    const [clientIsDesktop, setClientIsDesktop] = useState<boolean>(true);
    const [buttonStyle, setButtonStyle] = useState(styles.backToTopButton);
    const [containerStyle, setContainerStyle] = useState(styles.containerStyle);

    const { sm } = useThemeMediaQuery();
    const { get: getMicrocopy } = useMicrocopy();

    // Memoize the handleScroll as it won't change unless clienIsDesktop changes.
    const handleScroll = useCallback(() => {
        const scrollPosition = window.scrollY;
        const newButtonStyle = { ...styles.backToTopButton };
        const newContainerStyle = { ...styles.containerStyle };

        // If the scroll is larger than 500px, show the button.
        if (scrollPosition > 500) {
            newButtonStyle.opacity = 1;
            newButtonStyle.visibility = "visible";
            newButtonStyle.pointerEvents = "auto";
        } else {
            newButtonStyle.opacity = 0;
            newButtonStyle.visibility = "hidden";
            newButtonStyle.pointerEvents = "none";
        }

        // In desktop we show the button just above the footer when it appears on screen.
        // In mobile, it stays in the same location fixed at the bottom.
        newContainerStyle.position = clientIsDesktop ? "sticky" : "fixed";

        setButtonStyle(newButtonStyle);
        setContainerStyle(newContainerStyle);
    }, [clientIsDesktop]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // Throttle the handleScroll to keep performance.
    const throttledHandleScroll = throttle(handleScroll, 80);

    useEffect(() => {
        window.addEventListener("scroll", throttledHandleScroll);
        return () => {
            window.removeEventListener("scroll", throttledHandleScroll);
        };
    }, [clientIsDesktop]);

    useEffect(() => {
        setClientIsDesktop(sm);
    }, [sm]);

    return (
        <Box sx={containerStyle}>
            <Button
                rightIcon={<ECCOIcon transform={"rotate(-90deg)"} name="arrow-right" />}
                variant={"standard"}
                size={"sm"}
                aria-hidden="true"
                onClick={scrollToTop}
                sx={{
                    ...buttonStyle,
                    "& .chakra-button__icon": {
                        // Fixed needed as chakra adds a mr to the span around the icon.
                        // The ECCOIcon sx props is only applied to the icon itself, not its wrapper.
                        ms: clientIsDesktop ? "" : "0",
                    },
                }}
            >
                {clientIsDesktop ? (
                    <span>{getMicrocopy(CommonCMS.global, CommonCMS.backToTop)}</span>
                ) : (
                    ""
                )}
            </Button>
        </Box>
    );
};

export default BackToTop;
