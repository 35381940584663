import { Divider } from "@ui/components/data-display/divider/Divider";
import { Link } from "@components/cms/link/Link";
import type { IFooter } from "@contentful-api/types/contentful";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Container } from "@ui/components/layout/container/Container";
import { Flex } from "@ui/components/layout/flex/Flex";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { itemTitle, menuColumns } from "../footer-menu/menu-columns/MenuColumns";
import { styles } from "./styles";

export interface CheckOutFooterProps {
    footerData: IFooter;
}

declare global {
    interface Window {
        showCookieBanner: () => void;
    }
}

export const CheckOutFooter: FC<CheckOutFooterProps> = ({ footerData }) => {
    const { get: getMicrocopy } = useMicrocopy();
    const copyright = footerData?.fields?.copyright;
    const policiesLinkCollections = footerData?.fields?.linkCollections?.filter(
        (linkCollection) => {
            return linkCollection?.fields?.title?.toLocaleLowerCase().includes("policies");
        }
    );

    if (isEmpty(policiesLinkCollections)) return null;

    return (
        <Container sx={styles.mainContainer}>
            <Divider sx={styles.divider} data-testid="divider" />
            <Container sx={styles.container} size="desktop">
                <Flex data-testid={menuColumns} sx={styles.menuContainer}>
                    <Box
                        sx={styles.copyright}
                        maxW={{ base: "100%", lg: "50%" }}
                        data-testid="footer-copyright-text"
                    >
                        {copyright}
                    </Box>

                    <Flex
                        sx={styles.policies}
                        maxW={{ base: "100%", lg: "50%" }}
                        data-testid="footer-policies"
                    >
                        {policiesLinkCollections?.map((link, index) => (
                            <Box key={link?.sys?.id}>
                                {link?.fields?.links?.map((link) => (
                                    <Link
                                        key={link?.sys?.id}
                                        link={link}
                                        data-testid={itemTitle}
                                        sx={styles.link}
                                    />
                                ))}
                                {index + 1 === policiesLinkCollections.length && (
                                    <Button
                                        variant="link"
                                        key={link?.sys?.id}
                                        data-testid="renewcookie"
                                        sx={styles.button}
                                        onClick={() => window.showCookieBanner()}
                                        ml={8}
                                    >
                                        <span>
                                            {getMicrocopy(CommonCMS.page, CommonCMS.cookieRenew)}
                                        </span>
                                    </Button>
                                )}
                            </Box>
                        ))}
                    </Flex>
                </Flex>
            </Container>
        </Container>
    );
};

export default CheckOutFooter;
