import {
    categoryTitleMobileStyles,
    mobileButtonBackStyles,
    navItemMobileStyles,
    parentThumbnailContainerStyles,
    seeAllCategoriesMobileStyles,
    thumbnailCarouselContainerStyles,
    thumbnailCarouselItemStyles,
} from "@components/layouts/navigation/styles";
import type { INavigationItem } from "@contentful-api/types/contentful";
import { Text } from "@ui/components/content/text/Text";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Container } from "@ui/components/layout/container/Container";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import { motion } from "framer-motion";
import { isEmpty } from "lodash-es";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { CategoryTile } from "../components/CategoryTile";
import { MobileSubNavItem } from "./MobileSubNavItem";

export interface MobileNavItemProps {
    navigationItem: INavigationItem;
    handleFirstMenuBgColor: () => void;
}

export const MobileNavItem = ({ navigationItem, handleFirstMenuBgColor }: MobileNavItemProps) => {
    const { getButtonProps, getDisclosureProps, isOpen, onClose } = useDisclosure();
    const [hidden, setHidden] = useState(!isOpen);
    const { asPath } = useRouter();

    const handleMotionStart = (onAnimationStartStatus: boolean) => {
        handleFirstMenuBgColor();
        setHidden(onAnimationStartStatus);
    };

    useEffect(() => {
        const interval = setTimeout(() => {
            onClose();
        }, 1000);
        return () => clearTimeout(interval);
    }, [asPath, onClose]);

    const primaryLink = navigationItem?.fields?.primaryLink?.fields;
    const thumbnailLinks = navigationItem?.fields?.thumbnailLinks;
    const linkCollections = navigationItem?.fields?.linkCollections;
    const thumbnailLinksTiles = navigationItem?.fields?.thumbnailLinksTiles;
    const fallbackImage = navigationItem?.fields?.fallbackImage;
    const bottomLinkLabel = navigationItem?.fields?.bottomLinkLabel;
    const hotspot = navigationItem?.fields?.hotspot;

    return (
        /* First Level Navigation Mobile */
        <Flex direction="column" data-testid="FirstLevNavMobile">
            <Flex
                as={Button}
                colorScheme="white"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                variant="solid"
                {...getButtonProps()}
                sx={navItemMobileStyles}
            >
                <Text sx={categoryTitleMobileStyles}>{primaryLink?.label.toUpperCase()}</Text>
                <ECCOIcon aria-label="Right arrow" name="chevron" transform="rotate(-90deg)" />
            </Flex>
            <motion.div
                {...getDisclosureProps()}
                hidden={hidden}
                initial={false}
                onAnimationStart={() => handleMotionStart(false)}
                onAnimationComplete={() => setHidden(!isOpen)}
                animate={{ left: isOpen ? "0%" : "100%" }}
                transition={{ ease: "easeOut", duration: 0.15 }}
                align={"start"}
                style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    position: "absolute",
                    right: "0",
                    top: "0",
                    zIndex: 3,
                    width: "100%",
                    marginTop: 0,
                }}
            >
                {/* Second Level Navigation Mobile */}
                <Flex
                    as={Button}
                    colorScheme="white"
                    variant="solid"
                    sx={mobileButtonBackStyles}
                    {...getButtonProps()}
                >
                    <ECCOIcon
                        aria-label="Right arrow"
                        name="chevron"
                        color="black"
                        transform="rotate(90deg)"
                    />
                    <Text fontSize="desktopHeading5" fontWeight={"semibold"}>
                        {primaryLink?.label.toUpperCase()}
                    </Text>
                </Flex>
                <Container
                    h="100%"
                    overflow={"auto"}
                    marginTop={"1px"}
                    bg={"white"}
                    px={"0"}
                    maxW={"100%"}
                >
                    {!isEmpty(thumbnailLinks) ? (
                        <Box sx={parentThumbnailContainerStyles} data-testid="ThumbnailLinks">
                            <Flex sx={thumbnailCarouselContainerStyles}>
                                <Flex sx={thumbnailCarouselItemStyles}>
                                    {thumbnailLinks?.map?.((link) => (
                                        <CategoryTile
                                            key={link?.sys?.id}
                                            link={link}
                                            isLeftSide={true}
                                            imageSizes={["200vw"]}
                                        />
                                    ))}
                                </Flex>
                            </Flex>
                        </Box>
                    ) : (
                        <>
                            {/* Third level navigation mobile */}
                            {bottomLinkLabel && (
                                <Flex
                                    as={NextLink}
                                    href={primaryLink?.completeSlug}
                                    sx={seeAllCategoriesMobileStyles}
                                >
                                    {bottomLinkLabel.toUpperCase()}
                                </Flex>
                            )}
                            {!isEmpty(linkCollections) &&
                                linkCollections?.map((linkCollection) => (
                                    <MobileSubNavItem
                                        key={linkCollection.sys?.id}
                                        title={linkCollection?.fields?.title.toUpperCase()}
                                        links={linkCollection?.fields?.links}
                                    />
                                ))}
                        </>
                    )}
                    <Flex flexDir={"column"} gap={8} justifyContent="space-between" pb={4} px={4}>
                        {!isEmpty(hotspot) && (
                            <Box>
                                {/* @TODO: Cleanup Hotspots from Navigation */}
                                {/* <NextImage
                                        objectFit={"cover"}
                                        rounded={"xl"}
                                        width="100%"
                                        height="100%"
                                        sx={{ aspectRatio: "3/2" }}
                                        src={hotspot?.fields?.image?.fields?.file?.url}
                                        alt="promo image"
                                    />
                                    {hotspot?.fields?.title && (
                                        <Text
                                            whiteSpace={"initial"}
                                            fontSize={"desktopHeading5"}
                                            fontWeight="semibold"
                                            mt={4}
                                            as={"h5"}
                                        >
                                            {hotspot?.fields?.title}
                                        </Text>
                                    )}
                                    {hotspot?.fields?.description && (
                                        <Text
                                            whiteSpace={"initial"}
                                            fontWeight={"semibold"}
                                            color="gray.200"
                                            fontSize="desktopBodyTextSmall"
                                            mt={2}
                                            as={"p"}
                                        >
                                            {hotspot?.fields?.description}
                                        </Text>
                                    )} */}
                            </Box>
                        )}

                        {!isEmpty(thumbnailLinksTiles) ? (
                            thumbnailLinksTiles?.map?.((link) => {
                                return (
                                    <CategoryTile
                                        key={link?.sys?.id}
                                        link={link}
                                        imageSizes={["200vw"]}
                                    />
                                );
                            })
                        ) : (
                            <NextImage
                                objectFit={"cover"}
                                layout={"responsive"}
                                src={fallbackImage?.fields?.file?.url}
                                alt="promo image"
                                sizes={["200vw"]}
                            />
                        )}
                    </Flex>
                </Container>
            </motion.div>
        </Flex>
    );
};
